import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (vm.$i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
    const bodySeperate = []
        bodySeperate.push(
          [
            { text: vm.$t('globalTrans.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.fiscal_year_bn : vm.item.fiscal_year, style: 'td', alignment: 'left' },
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.org_bn : vm.item.org, style: 'td', alignment: 'left' }
          ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.training_type_bn : vm.item.training_type, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.training_category_bn : vm.item.training_category, style: 'td', alignment: 'left' }
          ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.training_title_bn : vm.item.training_title, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.circular_memo_no : vm.item.circular_memo_no, style: 'td', alignment: 'left' }
          ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_iabm.batch_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? vm.item.batch_bn : vm.item.batch, style: 'td', alignment: 'left', colSpan: 4 },
            {},
            {},
            {}
          ]
        )

        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['22%', '3%', '25%', '22%', '3%', '25%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('elearning_tim.trainee') + ' ' + vm.$t('globalTrans.list'), style: 'hh', alignment: 'center' })
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_tpm.work_place'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'center' }
        ]
      ]
      // table body
      if (vm.trainee.length) {
        vm.trainee.filter((item, index) => {
          let professionType = ''
          let designation = ''
          if (parseInt(item.profession_type) === 1 && item.not_here_office === 1) {
            professionType = ((vm.$i18n.locale === 'en') ? item.office_name : item.office_name_bn)
          }
          if (parseInt(item.profession_type) === 1 && item.not_here_office === 0) {
            professionType = (vm.getOfficeName(item.professional_office_id)) + ', ' + (vm.getOrgName(item.professional_org_id))
          }
          if (parseInt(item.profession_type) !== 1) {
            professionType = vm.$i18n.locale === 'en' ? item.professional_other_org_name : item.professional_other_org_name_bn
          }

          if (item.designation_id && item.not_here_designation === 0 && parseInt(item.profession_type) === 1) {
            designation = vm.getDesignation(item.designation_id)
          } else {
            designation = vm.$i18n.locale === 'bn' ? item.designation_bn : item.designation_en
          }

          // const professionType = item.profession_type === 1 ? i18n.locale === 'en' ? item.org : item.org_bn : i18n.locale === 'bn' ? item.professional_other_org_name_bn : item.professional_other_org_name
           const rowItem = [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'en' ? item.name : item.name_bn, style: 'td', alignment: 'center' },
            // { text: (item.not_here_designation === 0 && item.designation_id && item.profession_type === 1) ? (vm.getDesignation(item.designation_id)) : ((vm.$i18n.locale === 'en') ? item.designation_en : item.designation_bn), style: 'td', alignment: 'center' },
            { text: designation, style: 'td', alignment: 'center' },
            { text: professionType, style: 'td', alignment: 'center' },
            { text: vm.$n('0') + vm.$n(item.mobile, { useGrouping: false }), style: 'td', alignment: 'left' },
            { text: item.email, style: 'td', alignment: 'center' }
           ]
           allRows.push(rowItem)
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 7 },
          { },
          { },
          { },
          { }
        ]
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '17%', '17%', '21%', '16%', '22%'],
          body: allRows
        }
      })
      // const waterMarkText = vm.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
